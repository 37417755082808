<template>
  <div class="app-container">
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-form-item label="学期" prop="semester">
        <el-select
          v-model="form.semester"
          placeholder="请选择"
          style="width: 200px"
        >
          <el-option label="第一学期" :value="1"> </el-option>
          <el-option label="第二学期" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="概述" prop="summary">
        <el-input
          v-model="form.summary"
          type="textarea"
          :rows="4"
          clearable
          style="width: 500px; margin-bottom: 5px"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="总课时" prop="total_class_hour">
        <el-input
          type="number"
          onkeyup="this.value=this.value.replace(/\D/g,'')"
          onafterpaste="this.value=this.value.replace(/\D/g,'')"
          v-model="form.total_class_hour"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <el-input
          type="number"
          v-model="form.price"
          placeholder="请输入内容"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input>
      </el-form-item>
      <el-form-item label="课程详情" prop="details">
        <tinymce :height="400" v-model="form.details" :value="form.details" />
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="submit" :loading="btnLoading"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";
export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      input: "",
      form: {
        id: null,
        name: "",
        summary: "",
        total_class_hour: "",
        price: "",
        details: "",
      },
      options: [],
      value: "",
      btnLoading: false,
      rules: {
        name: [
          { required: true, message: "课程名称不能为空", trigger: "blur" },
        ],
        summary: [{ required: true, message: "概述不能为空", trigger: "blur" }],
        total_class_hour: [
          {
            required: true,
            message: "总课时不能为空",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "价格不能为空",
            trigger: "blur",
          },
        ],
        details: [
          {
            required: true,
            message: "课程详情不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    // 'form.total_class_hour'(newV,oldV){
    //   // console.log(newV);
    //   if (newV<0) {
    //     this.form.total_class_hour=''
    //   }
    // }
  },
  created() {
    if (this.$route.query.id !== "null") {
      this.form.id = this.$route.query.id;
      this.getDetail(this.form.id);
    }
  },
  mounted() {},
  methods: {
    valueChange(e) {
      console.log(e);
      this.form.price = e.replace(/(^\s*)|(\s*$)/g, "");
      const reg = /[^\d.]/g;
      // 只能是数字和小数点，不能是其他输入
      this.form.price = e.replace(reg, "");
      // 保证第一位只能是数字，不能是点
      this.form.price = e.replace(/^\./g, "");
      // 小数只能出现1位
      this.form.price = e
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // 小数点后面保留2位
      this.form.price = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
    },
    proving(e) {
      let keynum = window.event ? e.keyCode : e.which;
      console.log(keynum, "========");
      let keychar = String.fromCharCode(keynum);
      if (
        keynum == 189 ||
        keynum == 190 ||
        keynum == 110 ||
        keynum == 109 ||
        keynum == 16
      ) {
        e.target.value = "";
      }
    },
    delet(key) {
      console.log(key);
    },
    getDetail(val) {
      this.listLoading = true;
      request({
        url: "/api/schoolend/otherCourse/detail",
        method: "get",
        params: { id: val },
      }).then((response) => {
        this.form = response.data;
        this.listLoading = false;
      });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/otherCourse/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1);
              // this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  .tabs {
    position: relative;
    border: 1px solid #d3d3d3;
    padding: 0 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    .el-icon-circle-close {
      position: absolute;
      color: red;
      top: -9px;
      right: -9px;
      font-size: 18px;
    }
  }
}
.btnBox {
  margin-top: 20px;
}
// .el-form-item {
//   width: 100%;
// }
.el-input {
  width: 500px;
}
</style>